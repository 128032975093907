import React from 'react';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import get from 'lodash/get';

const ProjectBlock = ({project}) => {
    return (
        <Link
            className="inline-block w-full sm:w-1/3 p-2"
            to={`/project/${project.slug}`}>
            <div className="overflow-hidden rounded shadow -p-2">
                <Img
                    loading="lazy"
                    style={{height: '10rem'}}
                    alt={project.title}
                    fluid={project.heroImage.fluid}
                />
            </div>
        </Link>
    );
};

export default ({data}) => {
    const projects = get(data, 'edges', []);

    return (
        <div>
            <h1 className="text-3xl text-gray-800">Projects I've worked on</h1>

            <div className="relative flex flex-row flex-wrap -mx-2">
                {projects.map(({node}) => (
                    <ProjectBlock key={node.id} project={node} />
                ))}
            </div>
        </div>
    );
};
