import React, {useState} from 'react';
import {Link} from 'react-scroll';
import {animated, useSpring} from 'react-spring';

const MenuLink = ({id, title}) => {
    const [isActive, setIsActive] = useState(false);
    const props = useSpring({width: isActive ? 15 : 0});

    return (
        <div className="flex flex-col justify-center items-center">
            <Link
                smooth
                to={id}
                spy
                onSetActive={() => setIsActive(true)}
                onSetInactive={() => setIsActive(false)}
                className={`text-white cursor-pointer mx-4 ${isActive &&
                    'font-bold'}`}>
                {title}
            </Link>

            <animated.div
                className="h-px bg-white menu-link-selection-indicator"
                style={props}
            />
        </div>
    );
};

export default ({sections, currentVisibleSection}) => {
    return (
        <div className="fixed rounded menu p-2 bg-gray-800 shadow-md flex flex-row z-10">
            {sections.map(section => (
                <MenuLink
                    {...section}
                    key={section.id}
                    selected={currentVisibleSection === section.id}
                />
            ))}
        </div>
    );
};
