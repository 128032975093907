import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import {FiMapPin} from 'react-icons/fi';
import Img from 'gatsby-image';

export default () => (
    <>
        <div>
            <StaticQuery
                query={graphql`
                    query {
                        file(relativePath: {eq: "profile.png"}) {
                            childImageSharp {
                                fixed(width: 125, height: 125, quality: 100) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <div data-aos="fade-up">
                        <Img
                            className="w-24 h-24 rounded-full shadow-lg"
                            alt={"Rodolfo's Picture"}
                            fixed={data.file.childImageSharp.fixed}
                        />
                    </div>
                )}
            />
            <h1
                data-aos="fade-up"
                data-aos-delay="500"
                className="text-3xl text-gray-800">
                Hello,
                <br />
                I'm Rodolfo
            </h1>

            <p
                data-aos="fade-up"
                data-aos-delay="1000"
                className="text-gray-700">
                A full stack developer focused on mobile applications.
            </p>

            <p
                data-aos="fade-up"
                data-aos-delay="1000"
                className="text-gray-500">
                <FiMapPin className="inline" /> Brisbane, Australia
            </p>
        </div>
    </>
);
