import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {graphql} from 'gatsby';

import ContentWrapper from '../components/ContentWrapper';

import Menu from '../components/Menu';
import Layout from '../components/Layout';

import {Element} from 'react-scroll';

import Header from '../components/homepage/Header';
import About from '../components/homepage/About';
import Projects from '../components/homepage/Projects';
import Blog from '../components/homepage/Blog';

export default ({data}) => {
    const Sections = [
        {
            component: Header,
            title: 'Intro',
            id: 'header',
        },
        {
            component: About,
            id: 'about',
            title: 'About',
        },
        {
            component: () => <Projects data={data.allContentfulProject} />,
            title: 'Projects',
            id: 'projects',
        },
        {
            component: () => <Blog data={data.allContentfulBlogPost} />,
            title: 'Blog',
            id: 'blog',
        },
    ];

    useEffect(() => {
        AOS.init({
            duration: 700,
            once: true,
        });
    }, []);

    return (
        <Layout id="homepage">
            <Menu sections={Sections} />

            {Sections.map(section => {
                return (
                    <Element key={section.id} name={section.id}>
                        <ContentWrapper id={section.id}>
                            <section.component />
                        </ContentWrapper>
                    </Element>
                );
            })}
        </Layout>
    );
};

export const pageQuery = graphql`
    query HomeQuery {
        allContentfulBlogPost(
            limit: 3
            sort: {fields: [publishDate], order: DESC}
        ) {
            edges {
                node {
                    title
                    slug
                    publishDate(formatString: "MMMM Do, YYYY")
                    tags
                    heroImage {
                        sizes(
                            maxWidth: 350
                            maxHeight: 196
                            resizingBehavior: SCALE
                        ) {
                            ...GatsbyContentfulSizes_withWebp
                        }
                    }
                    description {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
        allContentfulProject {
            edges {
                node {
                    id
                    heroImage {
                        fluid(maxWidth: 500, quality: 100) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    slug
                    title
                    body {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
        allContentfulPreviousExperience {
            edges {
                node {
                    id
                    heroImage {
                        fluid(maxWidth: 800) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    slug
                    title
                    dateFromTo
                    body {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }
        }
    }
`;
