import React from 'react';
import Link from '../Link';

import {
    FaReact,
    FaAws,
    FaWordpress,
    FaGitAlt,
    FaDrupal,
    FaNodeJs,
} from 'react-icons/fa';
import {DiMongodb, DiMysql} from 'react-icons/di';

const skills = [
    {name: 'React Native', Icon: FaReact, classes: 'bg-green-700'},
    {name: 'React', Icon: FaReact, classes: 'bg-green-700'},
    {name: 'Node.js', Icon: FaNodeJs, classes: 'bg-green-700'},
    {name: 'MongoDB', Icon: DiMongodb, classes: 'bg-yellow-600'},
    {name: 'MySQL', Icon: DiMysql, classes: 'bg-yellow-600'},
    {name: 'AWS', Icon: FaAws, classes: 'bg-yellow-600'},
    {name: 'WordPress', Icon: FaWordpress, classes: 'bg-gray-600'},
    {name: 'Drupal', Icon: FaDrupal, classes: 'bg-gray-600'},
    {name: 'Git', Icon: FaGitAlt, classes: 'bg-gray-600'},
];

export default () => (
    <>
        <div>
            <h1 data-aos="fade-up" className="text-3xl text-gray-800">
                About Me
            </h1>
            <p className="text-gray-600" data-aos="fade-up">
                Hello, I'm a full stack developer with{' '}
                <strong>7 years of experience</strong> based in Brisbane,
                Australia. I'm specialised in creating beautiful{' '}
                <strong>mobile apps for iOS and Android</strong> from start to
                finish - including wireframing, prototyping, coding and launch.
                <br />I also develop websites and have experience in many
                industries: education, charity, health care, not-for-profit,
                entertainment, governmental and more.
                <br />
                <br />
            </p>

            <div data-aos="fade-up">
                <p className="text-xl font-bold text-gray-700 inline-block">
                    I'm currently on the look for side gigs.{' '}
                </p>{' '}
                <Link
                    className="ml-0 sm:ml-2 block sm:inline-block"
                    href="mailto:rodolfo.perottoni@gmail.com">
                    Let's talk?
                </Link>
            </div>
        </div>

        <div data-aos="fade-up">
            <h1 className="text-3xl text-gray-800 mt-6">My Skills</h1>
            <div className="mt-2 flex flex-row flex-wrap">
                {skills.map(({name, Icon, classes}, idx) => (
                    <div
                        key={name}
                        className={`select-none items-center flex flex-col shadow rounded px-4 py-4 sm:py-2 mr-2 mb-2`}>
                        <Icon className="text-2xl text-orange-600" />
                        <p className="whitespace-no-wrap text-gray-700">
                            {name}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    </>
);
