import React from 'react';
import {useGesture} from 'react-with-gesture';
import {useSpring, animated} from 'react-spring';

const trans = s => `perspective(500px)  scale(${s})`;

export default ({children, className, ...rest}) => {
    const [bind, {down}] = useGesture();
    const [props, set] = useSpring(() => ({
        s: 1,
        config: {mass: 5, tension: 350, friction: 40},
    }));

    return (
        <animated.div
            className="inline-block text-white"
            onMouseMove={() => set({s: 1.1})}
            onMouseLeave={() => set({s: 1})}
            style={{
                transform: props.s.interpolate(trans),
            }}>
            <a
                className={`transition-ease relative border-orange-600 border-2 font-bold text-orange-600 rounded shadow-lg px-4 py-1 ${className}`}
                {...rest}>
                {children}
            </a>
        </animated.div>
    );
};
