import React from 'react';
import get from 'lodash/get';
import BlogPreview from '../blog/BlogPreview';

const HomepageBlogSection = ({data}) => {
    const posts = get(data, 'edges', []);

    return (
        <div>
            <div className="wrapper">
                <h1 className="section-headline">Recent articles</h1>
                <ul className="article-list">
                    {posts.map(({node}) => {
                        return (
                            <li key={node.slug}>
                                <BlogPreview article={node} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default HomepageBlogSection;
